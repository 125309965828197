import React, { useContext } from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import { parse } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { de } from "date-fns/locale"

import Columns from "./columns"
import Column from "./column"
import Stack from "./stack"
import Inline from "./inline"
import Heading from "./heading"
import Text from "./text"

import { EventListContext } from "./event-list"

const EventListItem = (props) => {
  const { event } = props

  const { eventHeadingElement, eventDensity } =
    useContext(EventListContext) || false

  const { reihe, titel, untertitel, start, ende, ort } = event.childMdx.frontmatter

  const date = parse(start, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date())
  const dateEnd =
    ende && parse(ende, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date())

  const getMonth = formatInTimeZone(date, "Europe/Berlin", "L", { locale: de })
  const season =
    getMonth >= 3 && getMonth <= 5
      ? "spring"
      : getMonth >= 6 && getMonth <= 8
      ? "summer"
      : getMonth >= 9 && getMonth <= 11
      ? "autumn"
      : "winter"

  const paddingX = {
    default: [3, 6],
    compact: 3,
  }

  const paddingY = {
    default: [6, 12],
    compact: [6],
  }

  const textSizeLarge = {
    default: [4, 6, 7],
    compact: [3, 4, 5],
  }

  const textSizeDefault = {
    default: [3, 4, 5],
    compact: 3,
  }

  const titleLevel = {
    default: 3,
    compact: 5,
  }

  return (
    <Link
      to={`/veranstaltungen${event.childMdx.fields.slug}`}
      sx={{
        display: "block",
        px: paddingX[eventDensity],
        py: paddingY[eventDensity],
        ":hover, :focus": { bg: "muted" },
      }}
    >
      <Columns space={[6, 3]} collapse={[true, false]}>
        <Column width={["full", "1/3"]}>
          <Stack space={[1, 2]}>
            <Text
              as="span"
              size={textSizeLarge[eventDensity]}
              bold
              sans
              lineHeight="heading"
            >
              {formatInTimeZone(date, "Europe/Berlin", "dd. MMMM", {
                locale: de,
              })}
            </Text>
            <Inline space={2}>
              <Text
                color="whisper"
                as="span"
                size={textSizeDefault[eventDensity]}
                sans
                uppercase
                bold
              >
                {formatInTimeZone(date, "Europe/Berlin", "EEEEEE", {
                  locale: de,
                })}
              </Text>
              <Text
                color="whisper"
                as="span"
                size={textSizeDefault[eventDensity]}
                sans
              >
                {ende
                  ? `${formatInTimeZone(date, "Europe/Berlin", "p 'Uhr'", {
                      locale: de,
                    })} - ${formatInTimeZone(
                      dateEnd,
                      "Europe/Berlin",
                      "p 'Uhr'",
                      { locale: de }
                    )}`
                  : formatInTimeZone(date, "Europe/Berlin", "p 'Uhr'", {
                      locale: de,
                    })}
              </Text>
            </Inline>
            <Text
                color="whisper"
                as="span"
                size={textSizeDefault[eventDensity]}
                sans
            >
              {ort}
            </Text>
          </Stack>
        </Column>
        <Column>
          <Stack space={2}>
            {reihe && (
              <Text color="whisper" size={textSizeDefault[eventDensity]} sans>
                {reihe}
              </Text>
            )}
            <Heading
              as={eventHeadingElement || null}
              level={titleLevel[eventDensity]}
              color={season || null}
            >
              {titel}
            </Heading>
            {untertitel && (
              <Text color="whisper" size={textSizeDefault[eventDensity]} sans>
                {untertitel}
              </Text>
            )}
          </Stack>
        </Column>
      </Columns>
    </Link>
  )
}

export default EventListItem
