import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../components/box"
import Button from "../../components/button"
import Image from "../../components/image"
import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Text from "../../components/text"
import Stack from "../../components/stack"

const Featured = () => {
  const data = useStaticQuery(graphql`
    {
      dauerausstellung: file(
        relativePath: { eq: "ausstellungen/turmzimmer.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 500, height: 500, layout: CONSTRAINED)
        }
      }
      dauerausstellungSmall: file(
        relativePath: { eq: "ausstellungen/turmzimmer.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 640, height: 300, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <Box bg="muted">
      <Grid
        columns={[1, 1, 2]}
        space={0}
        reverse={[false, false, true]}
        alignY="center"
      >
        <Box>
          <Box display={["block", "block", "none"]}>
            <Image
              image={data.dauerausstellungSmall.childImageSharp.gatsbyImageData}
              alt="Auf einem Laptop ist ein Email-Programm geöffnet, das den Newsletter des Hölderlinturms zeigt."
            />
          </Box>
          <Box display={["none", "none", "block"]}>
            <Image
              image={data.dauerausstellung.childImageSharp.gatsbyImageData}
              alt="Auf einem Laptop ist ein Email-Programm geöffnet, das den Newsletter des Hölderlinturms zeigt."
            />
          </Box>
        </Box>
  
        <Box p={[6, 8, 12]}>
          <Stack space={6}>
            <Stack space={2}>
              <Heading as="h2" color="whisper">
                Dauerausstellung
              </Heading>
              <Heading as="strong" level={3}>
                Hölderlin in Tübingen
              </Heading>
            </Stack>
            <Text size={[2, 2, 3]}>
              Die Hälfte seines Lebens hat der Dichter Friedrich Hölderlin (1770-1843) 
              in jenem Tübinger Turm verbracht, der heute seinen Namen trägt. 
              Die darin gezeigte Ausstellung widmet sich Hölderlins letzten 36 Lebensjahren im Turm, 
              der Schönheit seiner Turmgedichte und der Fürsorge, 
              die er hier durch die Familie Zimmer empfing.{" "}
            </Text>
            <Button to="/ausstellungen/dauerausstellung">Zur Dauerausstellung</Button>
          </Stack>
        </Box>
      </Grid>
    </Box>
  )
}

export default Featured
