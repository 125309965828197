import React from "react"

import Layout from "../layouts/default"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../components/box"
import Bullet from "../components/bullet"
import BulletList from "../components/bullet-list"
import Button from "../components/button"
import EventList from "../components/event-list"
import Featured from "../content/startseite/featured"
import Grid from "../components/grid"
import Heading from "../components/heading"
import HyperCardStacked from "../components/hyper-card-stacked"
import Image from "../components/image"
import NewsletterBanner from "../components/newsletter-banner"
import Paragraph from "../components/paragraph"
import PinnedPost from "../components/pinned-post"
import Stack from "../components/stack"
import Text from "../components/text"
import TextLink from "../components/textlink"
import VisuallyHidden from "../components/visually-hidden"
import OpeningTimes from "../components/opening-times"
import Anchor from "../components/anchor"
import PageTitle from "../components/page-title"
import Breadcrumb from "../components/breadcrumb"
import { ExternalLink } from 'react-external-link';


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query Startseite {
      aussenAnsicht: file(
        relativePath: { eq: "startseite/turm-aussenansicht.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 600, layout: CONSTRAINED)
        }
      }
      neckarAnsicht: file(
        relativePath: { eq: "backdrops/wegbeschreibung.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 440, layout: CONSTRAINED)
        }
      }
      pinnedPostImageKeppler: file(
        relativePath: { eq: "startseite/keppler.jpg" }
      ) {
        ...pinnedPostImage
      }
      pinnedPostImagePodcast: file(
        relativePath: { eq: "backdrops/turmgespraeche.jpg" }
      ) {
        ...pinnedPostImage
      }
      pinnedPostImageCalendar: file(
        relativePath: { eq: "backdrops/bastelkalender.jpg" }
      ) {
        ...pinnedPostImage
      }
      pinnedPostImageLinocut: file(
        relativePath: { eq: "backdrops/linoldruck.jpg" }
      ) {
        ...pinnedPostImage
      }
      feuerPostkarte: file(
        relativePath: { eq: "og_images/feuer-cover.jpg" }
      ) {
        ...pinnedPostImage
      }
      pinnedPostImageSoundboard: file(
        relativePath: { eq: "startseite/soundboard.jpg" }
      ) {
        ...pinnedPostImage
      }
      videos: file(relativePath: { eq: "turm-tv/expertinnen.jpg" }) {
        ...mediumImage
      }
      turm: file(relativePath: { eq: "turm-tv/live.jpg" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Hölderlinturm Tübingen Digital"
        useTitleTemplate={false}
        description="Literaturmuseum, Dichterwohnhaus, Schreibwerkstatt, Veranstaltungshaus und Ausgangspunkt der Literaturvermittlung: Der Tübinger Hölderlinturm ist ein Ort, der zur Auseinandersetzung mit Literatur einlädt, sie sinnlich erfahrbar macht und selbst in die Literaturgeschichte eingegangen ist."
      />

      <VisuallyHidden>
        <Heading as="h1">Hölderlinturm Tübingen</Heading>
      </VisuallyHidden>
      <Stack space={[12, 24]}>
        {/*
        <Stack space={6}>
          <Breadcrumb
          items={[
            {
            title: "",
            link: "",
            },
          ]}
          />
          <PageTitle>Stellenangebot</PageTitle>
          <Paragraph dropcap={true}>
            Die Universitätsstadt Tübingen sucht für das Museum im Hölderlinturm 
            und das Hesse Kabinett eine_n <Text as="strong" style={{color: '#F7A61B'}}>Leitende_n Kurator_in</Text> (m/w/d). 
            <br />Die Stellenausschreibung mit weiteren Informationen finden Sie 
            <ExternalLink href="https://www.mein-check-in.de/6asA645JS5/position-324700">
              <span style={{color: '#F7A61B'}}> hier</span> 
            </ExternalLink>.
          </Paragraph>
        </Stack>
        */}
        <Featured />
        <Stack space={6}>
          <Heading as="h2" level={3}>
            Nächste Veranstaltungen
          </Heading>
          <Stack space={3}>
            <EventList limit={4} density="compact" />
            <Box textAlign="right">
              <Button to="/veranstaltungen" secondary>
                Alle Veranstaltungen
              </Button>
            </Box>
          </Stack>
        </Stack>
        <Stack space={6}>
          <Grid columns={[1, 2]} reverse={[false, true]}>
            {/*
            <Box bg="muted" p={[6, 12]} height="100%">
              <Stack space={6}>
                <Heading as="h3" level={4}>
                  Sonderöffnungszeiten über Ostern
                </Heading>
                <Paragraph size={3}>
                  Karfreitag, 29.03.2024:   geschlossen<br /><br />
                  Karsamstag, 30.03.2024:   11:00 - 17:00<br /><br />
                  Ostersonntag, 31.03.2024: 11:00 - 17:00<br /><br />
                  Ostermontag, 01.04.2023:  11:00 - 17:00
                </Paragraph>
              </Stack>
            </Box>
            
            <OpeningTimes size={[2, 3, 4]} />
            <Anchor id="oeffnungszeiten">
            </Anchor>
            */}
            <Image
              image={data.neckarAnsicht.childImageSharp.gatsbyImageData}
              alt="Aussenansicht Neckarfront"
            />
            
            <Stack space={6}>
              <Heading as="h2" level={3}>
                Öffnungszeiten
              </Heading>
              <OpeningTimes size={[2, 3, 4]} />
              <Anchor id="oeffnungszeiten"></Anchor>
              {/*
              <Text size={3}>
                *Am Tag der Deutschen Einheit (03. Oktober 2023) <br /> ist der Hölderlinturm geschlossen.
              </Text>
              */}
            </Stack>
          </Grid>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Neues aus dem Hölderlinturm
          </Heading>
          <Grid columns={[1, 2, 3]} space={12}>
            {/*
            <PinnedPost
              image={
                <Image
                  alt="Bunt bedruckte Postkarten mit Hölderlin-Wörtern."
                  image={
                    data.pinnedPostImageLinocut.childImageSharp.gatsbyImageData
                  }
                />
              }
              title="Lauter Lieblingswörter"
              description={`Mit unserem Linoldruck-Postkarten-Set können ganz individuelle Weihnachts- und Neujahrsgrußkarten gestaltet werden. Wie das geht, erklären wir Schritt für Schritt im dazugehörigen Online-Workshop. Das passende Set mit 10 Lieblingswörtern von Friedrich Hölderlin auf Blanko-Postkarten, Linolschnittplatten und den dazugehörigen Werkzeugen gibt es in unserem Museumsshop.`}
              to="/workshops/lauter-lieblingswoerter"
              cta="Zum Workshop"
            />
            */}
            <PinnedPost
              image={
                <Image
                  alt="Karikatur, der Hölderlinturm brennt und eine Gruppe erstaunter Touristen fährt in einem Stocherkahn auf dem Neckar am Turm vorbei."
                  image={
                    data.feuerPostkarte.childImageSharp.gatsbyImageData
                  }
                />
              }
              title="Sonderausstellung - Turm in Flammen!"
              description={`In der Nacht zum 15. Dezember 1875 brannte das Haus, in dem Friedrich Hölderlin seine zweite Lebenshälfte verbracht hat, nieder. Erst mit dem Wiederaufbau erhielt es seine berühmte Gestalt und wurde zum vorrangigen Ort der Erinnerung an den Dichter. Durch einen spektakulären Archivfund kann nun erstmals belegt werden, dass das Haus einer Brandstiftung zum Opfer fiel. Die Ausstellung geht diesem Kriminalfall nach.`}
              to="/sonderausstellungen/feuerteufel"
              cta="Zur Sonderausstellung"
            />
            <PinnedPost
              image={
                <Image
                  alt="Eine Pinnwand mit einem Kalender und vielen Bastelmaterialien."
                  image={
                    data.pinnedPostImageCalendar.childImageSharp.gatsbyImageData
                  }
                />
              }
              title="Kalender-Bastel- und Schreibworkshop"
              description={`Der Jahreswechsel rückt näher! In unserem digitalen Workshop stellen wir Ideen und Anregungen zur Gestaltung von 12 Kalenderseiten, inspiriert von Friedrich Hölderlin und seinen Gedichten, vor. Im Museumsshop gibt es dazu das passende Bastelset.`}
              to="/workshops/kalender"
              cta="Zum Workshop"
            />
            <PinnedPost
              image={
                <Image
                  alt="Auf einem Tablet sind bunte Kacheln in den Farben blau, grün, orange und gelb zu sehen. Eine Person wählt daraus die grüne Kachel mit der Aufschrift ›Der Frühling‹ aus."
                  image={
                    data.pinnedPostImageSoundboard.childImageSharp
                      .gatsbyImageData
                  }
                />
              }
              title="Jahreszeiten-Gedicht-Maschine"
              description="Für die Jahreszeiten-Gedicht-Maschine haben wir ein Frühlings-, ein Sommer-, ein Herbst- und ein Winter-Gedicht jeweils Vers für Vers von verschiedenen Sprecher*innen einlesen lassen. Aus diesen Versatzstücken kann sich jeder einen eigenen Jahreszeiten-Remix zusammenstellen."
              to="/jahreszeiten-gedicht-maschine"
              cta="Jetzt ausprobieren"
            />
          </Grid>
        </Stack>

        <Stack space={6}>
          <Heading as="h2" level={3}>
            Turm-TV
          </Heading>
          <Text size={3}>
            Kurz und knackig, lebendig und verständlich: In Expert*innen-Videos,
            Interviews und Live-Rundgängen greifen die Beiträge aus dem Turm-TV
            unterschiedlichste Themen aus Friedrich Hölderlins Biografie,
            Literatur und Wirkungsgeschichte auf und vermitteln sie.
          </Text>

          <Grid columns={[null, null, 2]} space={6}>
            <HyperCardStacked
              title="Expert*innen-Videos"
              alt="Eine Person wählt auf einem Touch-Pad eines der Videos aus dem Turm-TV-Kanal aus."
              headingElement="h3"
              description={`Expert*innen aus der Kunst und Kultur veranschaulichen die Machart und Wirkung von Hölderlins Werken sowie die zeitgeschichtlichen Ereignisse, vor denen sie entstanden sind.`}
              to="/videos"
              cta="Jetzt ansehen"
              image={data.videos}
            />
            <HyperCardStacked
              title="Hölderlinturm Live"
              alt="Eine Person sieht sich ein Turm-TV-Video auf dem Smartphone an."
              headingElement="h3"
              description="In unseren Live-Videos werden aktuelle Ereignisse aus unserem Museumsalltag zum Ausgangspunkt kurzer Rückblicke, Ausstellungsrundgänge oder Interviews mit Kooperationspartner*innen."
              to="/live"
              cta="Jetzt ansehen"
              image={data.turm}
            />
          </Grid>
        </Stack>

        <NewsletterBanner />

        <Stack space={6}>
          <Heading as="h2" level={3}>
            Über den Turm
          </Heading>

          <Grid columns={[1, 2]} reverse={[false, true]}>
            <Image
              image={data.aussenAnsicht.childImageSharp.gatsbyImageData}
              alt="Aussenansicht Hölderlinturm und Neckarfront"
              attribution="David Franck"
            />
            <Stack space={6}>
              <Paragraph size={[2, 2, 3]}>
                <TextLink to="/ausstellungen"> Literaturmuseum</TextLink>,
                Dichterwohnhaus, Schreibwerkstatt,{" "}
                <TextLink to="/veranstaltungen">Veranstaltungshaus</TextLink>{" "}
                und Ausgangspunkt der Literaturvermittlung: Der Tübinger
                Hölderlinturm ist ein literarischer Ort – ein Ort, der zur
                Auseinandersetzung mit Literatur einlädt, sie sinnlich erfahrbar
                macht und selbst in die Literaturgeschichte eingegangen ist.
              </Paragraph>
              <Paragraph size={[2, 2, 3]}>
                In diesem Turm am Neckar hat der Dichter Friedrich Hölderlin
                (1770 - 1843) die{" "}
                <TextLink to="/dauerausstellung/haelfte-des-lebens/">
                  zweite Hälfte seines Lebens
                </TextLink>{" "}
                verbracht. Zu Lebzeiten fast wirkungslos gilt er heute als einer
                der wichtigsten deutschsprachigen Dichter. Für Hölderlin war der
                Turm ein Ort des Rückzugs, der Abkehr von der Außenwelt. Hier
                entstanden seine spätesten Gedichte, die er häufig mit dem
                Pseudonym »Scardanelli« unterzeichnet hat. Hier wurde er von
                Zeitgenossen, Bewunderern und anderen Schriftstellern
                aufgesucht.
              </Paragraph>
              <Paragraph size={[2, 2, 3]}>
                Auch nach seinem Tod ließ das Interesse an dem Ort nicht nach.
                Heute zählt der Hölderlinturm zu den bedeutendsten literarischen
                Erinnerungsorten weltweit und ist in zahlreiche literarische,
                musikalische und künstlerische Werke eingegangen.
              </Paragraph>
            </Stack>
          </Grid>
        </Stack>

        <Grid columns={[1, 1, 2]} space={6}>
          <Box bg="muted" p={[6, 12]} height="100%">
            <Stack space={6}>
              <Heading as="h3" level={4}>
                Barrierefreiheit
              </Heading>
              <Paragraph size={3}>
                Leider ist der Hölderlinturm aufgrund seiner Altstadtlage nur
                über Treppen erreichbar. Man kann die Ausstellungen aber auch
                digital besuchen:
              </Paragraph>
              <Button to="/dauerausstellung">
                Zur digitalen Dauerausstellung
              </Button>
            </Stack>
          </Box>
          <Box bg="muted" p={[6, 12]} height="100%">
            <Stack space={6}>
              <Heading as="h3" level={4}>
                Der Hölderlinturm für Alle
              </Heading>
              <Paragraph size={3}>
                Zur Dauerausstellung gibt es einen{" "}
                <TextLink href="/mediaguide">inklusiven Media-Guide</TextLink>.
                Er enthält:
              </Paragraph>
              <BulletList size={3} space={6} color="primary">
                <Bullet icon="book">
                  Aufnahmen und Texte in Leichter Sprache
                </Bullet>
                <Bullet icon="gebaerdensprache">
                  Videos in Deutscher Gebärdensprache
                </Bullet>
                <Bullet icon="audio">
                  Audiodeskriptionen und Navigationshilfen für blinde Menschen
                  und Menschen mit Sehbeeinträchtigung
                </Bullet>
                <Bullet icon="translate">
                  Hör-Texte und Übersetzungen in englischer und französischer
                  Sprache
                </Bullet>
              </BulletList>
            </Stack>
          </Box>
        </Grid>
      </Stack>
    </Layout>
  )
}

export default IndexPage
