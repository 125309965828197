import React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"
import Stack from "./stack"
import EventListItem from "./event-list-item"
import Paragraph from "./paragraph"

export const EventListContext = React.createContext()

const EventList = ({
  headingElement,
  density,
  showPastEvents,
  category,
  limit,
}) => {
  const data = useStaticQuery(graphql`
    query EventListQuery {
      allFile(
        filter: {
          sourceInstanceName: { eq: "veranstaltungen" }
          extension: { eq: "mdx" }
        }
        sort: { order: ASC, fields: childMdx___frontmatter___start }
      ) {
        edges {
          node {
            childMdx {
              id
              excerpt
              fields {
                slug
              }
              frontmatter {
                reihe
                titel
                untertitel
                category
                start
                ende
                ort
              }
            }
          }
        }
      }
    }
  `)

  const { edges: events } = data.allFile

  const endOfDay = (date) => new Date(date).setHours(23, 59, 59, 999)

  const allEvents = events

  const upcomingEvents = events.filter(
    (event) => endOfDay(event.node.childMdx.frontmatter.start) >= Date.now()
  )

  const categorizedEvents = (
    showPastEvents ? allEvents : upcomingEvents
  ).filter(
    (event) =>
      event.node.childMdx.frontmatter.category &&
      event.node.childMdx.frontmatter.category === category
  )

  const filteredEvents = category
    ? categorizedEvents
    : showPastEvents
    ? allEvents
    : upcomingEvents

  return (
    <EventListContext.Provider
      value={{
        eventHeadingElement: headingElement,
        eventDensity: density,
      }}
    >
      {filteredEvents.length > 0 ? (
        <Stack as="ol" space={0} dividers>
          {filteredEvents
            .slice(0, limit)
            .map(({ node: event }, index) =>
              limit && index >= limit ? null : (
                <EventListItem event={event} key={`event${index}`} />
              )
            )}
        </Stack>
      ) : (
        <Paragraph>Aktuell sind keine Veranstaltungen geplant</Paragraph>
      )}
    </EventListContext.Provider>
  )
}

EventList.propTypes = {
  density: PropTypes.oneOf(["compact", "default"]),
  headingElement: PropTypes.string,
  category: PropTypes.string,
  limit: PropTypes.number,
  showPastEvents: PropTypes.bool,
}

EventList.defaultProps = {
  density: "default",
  headingElement: "h3",
  showPastEvents: false,
}

export default EventList
