import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */ import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import Box from "./box"
import Button from "./button"
import Paragraph from "./paragraph"
import Heading from "./heading"
import Stack from "./stack"

const PinnedPost = (props) => {
  const { title, description, image, to, cta } = props

  return (
    <Link
      to={to}
      sx={{
        display: "block",
        position: "relative",
        ":hover .pinned-post-cta, :focus .pinned-post-cta": {
          borderColor: "text",
        },
        ":hover .pinned-post-hover-box, :focus .pinned-post-hover-box": {
          display: "block",
        },
      }}
    >
      <Box overflow="hidden">
        <Stack space={6}>
          {image}
          <Heading as="h3" level={5} className="pinned-post-title">
            {title}
          </Heading>
          <Paragraph size={[2, 3]}>{description}</Paragraph>
          {cta && (
            <Button className="pinned-post-cta" secondary>
              {cta}
            </Button>
          )}
        </Stack>
      </Box>
      <Box
        className="pinned-post-hover-box"
        display="none"
        zIndex="negative"
        bg="muted"
        position="absolute"
        left={0}
        top={0}
        right={0}
        bottom={0}
        m={-6}
      ></Box>
    </Link>
  )
}

PinnedPost.propTypes = {
  cta: PropTypes.string.isRequired,
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default PinnedPost
